import React from "react"
import styled from "styled-components";

const SliderArrowLeft = (props) => {
    return (
        <StyledArrow onClick={props.onClick} id="slickArrowLeft">
                <svg width="100%" height="100%" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.36115 14.3015L7.26865 15.3613L0.134766 7.98104L7.28051 0.864937L8.35226 1.94262L2.2693 8.00187L8.36115 14.3015Z"
                        fill="#000000"/>
                </svg>
        </StyledArrow>
    );
}

export default SliderArrowLeft;

const StyledArrow = styled.div`
  left: -50px;
  width: 1rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); // this takes care of the vertical centering
`;
