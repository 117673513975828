import React from "react"
import styled from "styled-components";

const SliderArrowRight = (props) => {
    return (
        <StyledArrow onClick={props.onClick} id="slickArrowRight">
            <svg width="100%" height="100%" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.133476 1.92457L1.22598 0.864746L8.35986 8.24503L1.21412 15.3611L0.142368 14.2835L6.22533 8.22421L0.133476 1.92457Z" fill="#000000"/>
            </svg>
        </StyledArrow>
    );
}

export default SliderArrowRight;

const StyledArrow = styled.div`
  right: -50px;
  width: 1rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); // this takes care of the vertical centering
`;