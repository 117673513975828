import React from "react";
import Slider from "react-slick";

import "./slider.scss";
import SliderArrowLeft from "../slider-arrows/SliderArrowLeft";
import SliderArrowRight from "../slider-arrows/SliderArrowRight";

const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: <SliderArrowRight />,
    prevArrow: <SliderArrowLeft />
};

const GallerySlider = ({ data }) => (
    <div className='gallery'>
        <Slider {...settings}>
            {data.gallery.map(({ fluid }) => (
                <img alt={data.gallery.alt} key={fluid.src} src={fluid.src} />
            ))}
        </Slider>
    </div>
);

export default GallerySlider