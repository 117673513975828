import React from "react";

import "./work-header.scss";

const WorkHeader = ({ data }) => (
    <>
        <div className='work-header' style={{ backgroundColor: data.backgroundColor.hex, color: data.textColor.hex }}>
            <div className="work-headline">{data.title}</div>
            <div className="work-container">
                <div className="information-container">
                    <div className="information-block">
                        <strong>Auftrag</strong>
                        <p>{data.job}</p>
                    </div>
                    <div className="information-block">
                        <strong>Tätigkeiten</strong>
                        <p>{data.activities}</p>
                    </div>
                    <div className="information-block">
                        <strong>Tools</strong>
                        <p>{data.tools}</p>
                    </div>
                    <div className="information-block">
                        <strong>Team</strong>
                        <div style={{ color: data.textColor.hex }}
                            key={data.teamNode.childMarkdownRemark.id}
                            dangerouslySetInnerHTML={{
                                __html: data.teamNode.childMarkdownRemark.html
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default WorkHeader