import React from 'react'
import {HelmetDatoCms} from 'gatsby-source-datocms'
import {graphql, Link} from 'gatsby'
import styled from "styled-components"
import "./work.scss";
import GallerySlider from '../components/slider/slider';
import WorkHeader from '../components/work-header/work-header';
import Menu from '../components/menu/menu';
import Footer from "../components/footer/footer";

export default ({data}) => (
    <>
        <div className="content">
        <Menu leftTitle="projekte"/>
        <WorkHeader data={data.datoCmsWork}/>
        <article>
            <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags}/>
            {data.datoCmsWork.content.map((block) => (
                <div key={block.id}>

                    {/* title_category */}
                    {
                        block.model.apiKey === 'title_category' &&
                        <div className="container">
                            <div className="fullwidth-paragraph">
                                <h1 id={block.id}>{block.title}</h1>
                            </div>
                        </div>
                    }

                    {/* parapgrah_with_title */}
                    {
                        block.model.apiKey === 'parapgrah_with_title' &&
                        <div className="container" style={{paddingBottom: '1rem'}}>
                            <div className="fullwidth-paragraph"
                                 key={block.paragraphTitleNode.childMarkdownRemark.id}
                                 dangerouslySetInnerHTML={{
                                     __html: block.paragraphTitleNode.childMarkdownRemark.html
                                 }}
                            />
                            <div className="fullwidth-paragraph"
                                key={block.paragraphTextNode.childMarkdownRemark.id}
                                dangerouslySetInnerHTML={{
                                    __html: block.paragraphTextNode.childMarkdownRemark.html
                                }}
                            />
                        </div>
                    }

                    {/* paragraph */}
                    {
                        block.model.apiKey === 'paragraph' &&
                        <div className="container">
                            <div className="fullwidth-paragraph" style={{padding: '4rem 0 4rem 0'}}
                                 key={block.paragrafTextNode.childMarkdownRemark.id}
                                 dangerouslySetInnerHTML={{
                                     __html: block.paragrafTextNode.childMarkdownRemark.html
                                 }}
                            />
                        </div>
                    }

                    {/* image_gallery */}
                    {
                        block.model.apiKey === 'image_gallery' &&
                        <div className="container">
                            <GallerySlider data={block}/>
                        </div>
                    }

                    {/* image */}
                    {
                        block.model.apiKey === 'image' &&
                        <StyledImage backgroundColor={data.datoCmsWork.backgroundColor.hex}>
                            <div className="container">
                                <img src={block.image.fluid.src} alt={block.image.alt}/>
                            </div>
                        </StyledImage>
                    }

                </div>
            ))}

        </article>
        </div>
        <Footer/>
    </>
)

const StyledImage = styled.div`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  padding: 4rem 0 4rem 0;
  margin: 4rem 0 4rem 0;
`;

export const query = graphql`
    query WorkQuery($slug: String!) {
        datoCmsWork(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            excerpt
            tools
            job
            description
            activities
            textColor {
                hex
            }
            teamNode {
                childMarkdownRemark {
                    html
                    rawMarkdownBody
                }
            }
            backgroundColor {
                hex
            }
            descriptionNode {
                childMarkdownRemark {
                    html
                }
            }
            content {
                ... on DatoCmsTitleCategory {
                    id
                    model {
                        apiKey
                    }
                    title
                }
                ... on DatoCmsParapgrahWithTitle {
                    model { apiKey }
                    id
                    paragraphTitleNode {
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    paragraphTextNode {
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                }
                ... on DatoCmsParagraph {
                    id
                    model {
                        apiKey
                    }
                    paragrafTextNode {
                        childMarkdownRemark {
                            html
                            id
                        }
                    }
                }
                ... on DatoCmsImageGallery {
                    model { apiKey }
                    id
                    gallery {
                        fluid {
                            src
                        }
                        alt
                    }
                }
                ... on DatoCmsImage {
                    id
                    model {
                        apiKey
                    }
                    image {
                        fluid(maxWidth: 600, imgixParams: { fm: "png", auto: "compress" }) {
                            ...GatsbyDatoCmsSizes
                        }
                    }
                }
            }
            coverImage {
                url
                fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsSizes
                }
            }
        }
    }
`
